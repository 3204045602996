<template>
    <div class="cart" v-loading="loading">
        <template v-if="cartList.length || invalidGoods.length">
            <p class="red_text">温馨提示:绿色商品为最低价，不参与优惠券和满减活动</p>
            <nav>
                <li>
                    <el-checkbox v-model="checkAll" @change="allElection"></el-checkbox>
                </li>
                <li>商品信息</li>
                <li>价格</li>
                <li>数量</li>
                <li>小计</li>
                <li>操作</li>
            </nav>
            
            <div class="list" v-for="(siteItem, siteIndex) in cartList" :key="siteIndex">
                <div class="item">
                    <template v-for="(item, cartIndex) in siteItem.cartList">
                        <component :is="cartItemComponent(item)"
                                   :item="item" :key="cartIndex"
                                   @deleteCart="deleteCart(siteIndex, cartIndex)"
                                   @singleElection="singleElection(siteIndex, cartIndex)"
                                   @change="calculationTotalPrice"
                        ></component>
                    </template>
                </div>
            </div>
            
            <div class="lose-list" v-if="invalidGoods.length">
                <div class="head">
                    失效商品
                    <span class="ns-text-color">{{ invalidGoods.length }}</span>
                    件
                </div>
                <ul v-for="(goodsItem, goodsIndex) in invalidGoods" :key="goodsIndex">
                    <li>
                        <el-tag size="small" type="info">失效</el-tag>
                    </li>
                    <li class="goods-info-wrap">
                        <div class="img-wrap"><img class="img-thumbnail"
                                                   :src="$img(goodsItem.sku_image)"
                                                   @error="imageErrorInvalid(goodsIndex)" /></div>
                        <div class="info-wrap">
                            <h5>{{ goodsItem.sku_name }}</h5>
                            <template v-if="goodsItem.sku_spec_format">
                                <span v-for="(x, i) in goodsItem.sku_spec_format" :key="i"> {{ x.spec_name
                                    }}：{{ x.spec_value_name }}{{ i < goodsItem.sku_spec_format.length - 1 ? "；" : ""
                                    }} </span>
                            </template>
                        </div>
                    </li>
                    <li>
                        <span>￥{{ goodsItem.discount_price }}</span>
                    </li>
                    <li>{{ goodsItem.num }}</li>
                    <li>
                        <strong class="subtotal">￥{{ $util.filterPrice(goodsItem.discount_price * goodsItem.num)
                            }}</strong>
                    </li>
                </ul>
            </div>
            <div class="footer_tip" v-if="cartCalculateInfo!=null && cartCalculateInfo.manjian!=null">
                <p class="red_text">{{ cartCalculateInfo.manjian.discount_money>0?('已优惠'+cartCalculateInfo.manjian.discount_money+'元，'):'' }}再购{{ cartCalculateInfo.manjian.diff_money}}元，可减{{ cartCalculateInfo.manjian.diff_discount_money }}元</p>
            </div>
            <footer>
                <el-checkbox v-model="checkAll" @change="allElection">全选</el-checkbox>
                <ul class="operation">
                    <li>
                        <el-button type="text" @click="deleteCartSelected">删除</el-button>
                    </li>
                    <li>
                        <el-button type="text" @click="clearInvalidGoods" v-if="invalidGoods.length != 0">清除失效宝贝
                        </el-button>
                    </li>
                </ul>
                <div class="sum-wrap">
                    <div class="selected-sum">
                        <span>已选商品</span>
                        <em class="total-count">{{ totalCount }}</em>
                        <span>件</span>
                    </div>
                    <div class="price-wrap">
                        <span>合计（不含运费）：</span>
                        <strong class="ns-text-color">￥{{ calculationTotalPriceComputed }}</strong>
                    </div>
                    
                    <el-button type="primary" v-if="totalCount != 0" @click="settlement">结算</el-button>
                    <el-button type="info" v-else disabled @click="settlement">结算</el-button>
                </div>
            </footer>
        </template>
        <div class="empty-wrap" v-else-if="!loading && (!cartList.length || !invalidGoods.length)">
            <router-link to="/">您的购物车是空的，赶快去逛逛，挑选商品吧！</router-link>
        </div>
    
    
    </div>
</template>

<script>
    import cart from "./cart"
    import DefaultCartItem from "@/views/goods/cart/components/default-cart-item.vue"
    import SeckillCartItem from "@/views/goods/cart/components/seckill-cart-item.vue"
    import DiscountCartItem from "@/views/goods/cart/components/discount-cart-item.vue"
    import GetfreeCartItem from "@/views/goods/cart/components/getfree-cart-item.vue"
    import wholesaleCartItem from "@/views/goods/cart/components/wholesale-cart-item.vue"
    // import BundlingCartItem from "@/views/goods/cart/components/bundling-cart-item.vue"
    import BundlingCartItem from "@/views/goods/cart/components/bundling-cart-item.vue"
    export default {
        name: "cart",
        components: { DefaultCartItem },
        mixins: [cart],
        methods: {
            cartItemComponent(item) {
                if (item.promotion_label === "seckill") {
                    return SeckillCartItem
                }
                if (item.promotion_label === "discount") {
                    return DiscountCartItem
                }
                if (item.promotion_label === "wholesale") {
                    return wholesaleCartItem
                }
                if (item.get_free_goods) {
                    return GetfreeCartItem
                }
                if(item.bl_id) {
                    return BundlingCartItem
                }
                return DefaultCartItem
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "./cart.scss";
</style>

import {
    mapGetters
} from "vuex"
import {
    cartList,getCartCalculate
} from "@/api/goods/cart"

export default {
    data: () => {
        return {
            cartList: [], // 购物车
            checkAll: false,
            totalPrice: "0.00",
            totalCount: 0,
            invalidGoods: [], // 失效商品集合
            loading: true,
            modifyNum: 0, // 防止数量跳动
            modifyNumtwo: 0,
            cartCalculateInfo:null
        }
    },
    mounted() {
        this.getCartList()
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"]),
        calculationTotalPriceComputed() {
            let totalPrice = 0;
            this.cartList.forEach(siteItem => {
                siteItem.cartList.forEach(item => {
                    if (item.checked) {
                        totalPrice += Number(item.subtotal) || 0;
                    }
                })
            })
            return totalPrice.toFixed(2) || 0;
        }
    },
    methods: {
        // 获取购物车数据
        getCartList() {
            cartList({})
                .then(res => {
                    if (res.code >= 0) {
                        this.handleCartList(res.data, res.bundling);
                    }
                    this.loading = false
                })
                .catch(res => {
                    this.loading = false
                })
        },
        // 处理购物车数据结构
        handleCartList(data, bundling) {
            this.invalidGoods = []
            this.cartList = []
            let temp = {}
            const pushGoods = (data, statusKey= 'goods_state') => {
                data && data.forEach((item, index) => {
                    if (item[statusKey] === 1) {
                        item.checked = true
                        item.subtotal = 0
                        if (temp["site_" + item.site_id] != undefined) {
                            temp["site_" + item.site_id].cartList.push(item)
                        } else {
                            temp["site_" + item.site_id] = {
                                siteId: item.site_id,
                                siteName: item.site_name,
                                checked: true,
                                cartList: [item]
                            }
                        }
                    } else {
                        this.invalidGoods.push(item)
                    }
                });
            }
            pushGoods(data);
            pushGoods(bundling, 'status');


            this.invalidGoods.forEach(v => {
                if (v.sku_spec_format) {
                    v.sku_spec_format = JSON.parse(v.sku_spec_format)
                } else {
                    v.sku_spec_format = []
                }
            })
            Object.keys(temp).forEach(key => {
                this.cartList.push(temp[key])
            })
            this.calculationTotalPrice()
            this.cartList.forEach(v => {
                v.cartList.forEach(k => {
                    if (k.sku_spec_format) {
                        k.sku_spec_format = JSON.parse(k.sku_spec_format)
                    } else {
                        k.sku_spec_format = []
                    }
                })
            })
        },
        // 单选
        singleElection(siteIndex, index) {
            this.calculationTotalPrice()
        },
        // 店铺全选
        siteAllElection(index) {
            this.cartList[index].cartList.forEach(item => {
                item.checked = this.cartList[index].checked
            })
            this.calculationTotalPrice()
        },
        // 全选
        allElection() {
            if (this.cartList.length) {
                this.cartList.forEach(siteItem => {
                    siteItem.checked = this.checkAll
                    siteItem.cartList.forEach(item => {
                        item.checked = this.checkAll
                    })
                })
            }
            this.calculationTotalPrice()
        },
        // 计算购物车总价
        calculationTotalPrice(params) {
            if (this.cartList.length) {
                let totalPrice = 0,
                    totalCount = 0,
                    siteAllElectionCount = 0;
                let mapArr = [];
                this.cartList.forEach(siteItem => {
                    let siteGoodsCount = 0
                    
                    siteItem.cartList.forEach(item => {
                        let { num, price, goods_limit, discount_price } = item
                        if (item.checked) {
                            siteGoodsCount += 1
                            totalCount += 1
                            mapArr.push(item.cart_id);
                            // totalPrice += Number(item.subtotal) || 0;
                            // if(item.goods_limit > 0 ){
                            // 	let withinLimit = Math.min(num,goods_limit);
                            // 	let overLimit = Math.max(num - goods_limit,0);
                            // 	totalPrice += withinLimit * discount_price + overLimit * price;
                            // }else{
                            // 	totalPrice +=  item.num * item.discount_price
                            // }
                        }
                        this.modifyNum = 1
                        this.modifyNumtwo = item.min_buy
                    })
                    if (siteItem.cartList.length == siteGoodsCount) {
                        siteItem.checked = true
                        siteAllElectionCount += 1
                    } else {
                        siteItem.checked = false
                    }

                })
                this.getCartCalculate(mapArr.join(','));
                this.totalPrice = totalPrice.toFixed(2)
                this.totalCount = totalCount
                this.checkAll = this.cartList.length == siteAllElectionCount
            } else {
                this.totalPrice = "0.00"
                this.totalCount = 0
            }
        },
        getCartCalculate(str) {
            let obj = {
                cart_ids:str
            }
            getCartCalculate(obj).then(res => {
                this.cartCalculateInfo = res.data;
            })
        },
        // 删除单个
        deleteCart(siteIndex, cartIndex) {
            this.$confirm("确定要删除该商品吗？", "提示信息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$store
                    .dispatch("cart/delete_cart", {
                        cart_id: this.cartList[siteIndex].cartList[cartIndex].cart_id.toString()
                    })
                    .then(res => {
                        if (res.code >= 0) {
                            this.cartList[siteIndex].cartList.splice(cartIndex, 1)
                            if (this.cartList[siteIndex].cartList.length == 0) this.cartList.splice(siteIndex, 1)
                            this.calculationTotalPrice()
                            this.$message({
                                type: "success",
                                message: "删除成功"
                            })
                        } else {
                            this.$message({
                                message: res.message,
                                type: "warning"
                            })
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            })
        },
        // 删除选择的购物车
        deleteCartSelected() {
            var cartIds = []
            var selectedItem = []
            this.cartList.forEach((siteItem, siteIndex) => {
                siteItem.cartList.forEach((item, cartIndex) => {
                    if (item.checked) {
                        cartIds.push(item.cart_id)
                        selectedItem.push({
                            siteIndex: siteIndex,
                            cartIndex: cartIndex,
                            siteId: siteItem.siteId,
                            cartId: item.cart_id
                        })
                    }
                })
            })

            if (cartIds.length == 0) {
                this.$message({
                    message: "请选择要删除的商品",
                    type: "warning"
                })
                return
            }

            this.$confirm("确定要删除选择的商品吗？", "提示信息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$store
                    .dispatch("cart/delete_cart", {
                        cart_id: cartIds.toString()
                    })
                    .then(res => {
                        if (res.code >= 0) {
                            selectedItem.forEach(selectedItem => {
                                this.cartList.forEach((siteItem, siteIndex) => {
                                    siteItem.cartList.forEach((item, cartIndex) => {
                                        if (selectedItem.cartId == item.cart_id) {
                                            siteItem.cartList.splice(cartIndex, 1)
                                        }
                                        if (siteItem.cartList.length == 0) {
                                            this.cartList.splice(siteIndex, 1)
                                        }
                                    })
                                })
                            })
                            this.calculationTotalPrice()
                            this.$message({
                                type: "success",
                                message: "删除成功"
                            })
                        } else {
                            this.$message({
                                message: res.message,
                                type: "warning"
                            })
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            })
        },
        // 清空失效商品
        clearInvalidGoods() {
            this.$confirm("确认要清空这些商品吗？", "提示信息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                var cartIds = []
                this.invalidGoods.forEach(goodsItem => {
                    cartIds.push(goodsItem.cart_id)
                })
                if (cartIds.length) {
                    this.$store
                        .dispatch("cart/delete_cart", {
                            cart_id: cartIds.toString()
                        })
                        .then(res => {
                            if (res.code >= 0) {
                                this.invalidGoods = []
                                this.$message({
                                    type: "success",
                                    message: "删除成功"
                                })
                            } else {
                                this.$message({
                                    message: res.message,
                                    type: "warning"
                                })
                            }
                        })
                        .catch(err => {
                            this.$message.error(err.message)
                        })
                }
            })
        },
        // 变更购物车数量
        cartNumChange(num, params) {
            if (num % this.modifyNumtwo === 0) {
            } else {
                this.modifyNumtwo = 0
            }


            if (num < 1 || !num) num = 1
            // 防止数量跳动
            this.modifyNum = 0
            this.$store
                .dispatch("cart/edit_cart_num", {
                    num,
                    cart_id: this.cartList[params.siteIndex].cartList[params.cartIndex].cart_id
                })
                .then(res => {

                    if (res.code >= 0) {
                        this.cartList[params.siteIndex].cartList[params.cartIndex].num = num
                        this.calculationTotalPrice()
                    } else {
                        this.$message({
                            message: res.message,
                            type: "warning"
                        })
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                    this.modifyNum = 1
                })
        },
        // 结算
        settlement() {
            if (this.totalCount > 0) {
                let cart_ids = []
                this.cartList.forEach(siteItem => {
                    siteItem.cartList.forEach(item => {
                        if (item.checked) {
                            cart_ids.push(item.cart_id)
                        }
                    })
                })

                var data = {
                    cart_ids: cart_ids.toString()
                }
                this.$store.dispatch("order/setOrderCreateData", data)
                this.$router.push({
                    path: "/payment"
                })
            }
        },
        imageError(siteIndex, cartIndex) {
            this.cartList[siteIndex].cartList[cartIndex].sku_image = this.defaultGoodsImage
        },
        imageErrorInvalid(index) {
            this.invalidGoods[index].sku_image = this.defaultGoodsImage
        }
    }
}
